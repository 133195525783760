@import "../variable/variable.scss";
@import "../variable/mixin.scss";
.about {
  .heading {
    background: radial-gradient(
        circle,
        rgb(244 124 124 / 76%) 17%,
        rgb(191 223 249 / 94%) 94%
      ),
      url("https://www.cyberhelpindia.com/images/inner/about.jpg");
    padding: 5rem 0;
    text-align: center;
    h1 {
      font-size: 5rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .info {
    padding: 5rem 6rem;
    p {
      font-size: 1.1rem;
      line-height: 2.2rem;
      padding-bottom: 2rem;
    }
    h2 {
      text-transform: capitalize;
      font-weight: 600;
      color: $secondery-color;
      padding-bottom: 1rem;
    }
  }
}

@media screen and (max-width: 448px) {
  .about {
    .heading {
      background: radial-gradient(
          circle,
          rgb(244 124 124 / 76%) 17%,
          rgb(191 223 249 / 94%) 94%
        ),
        url("https://www.cyberhelpindia.com/images/inner/about.jpg");
      padding: 10rem 0 4rem;
      background-size: cover;
      text-align: center;
      h1 {
        font-size: 2rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .info {
      padding: 3rem 0rem;
      p {
        font-size: 0.7rem;
        line-height: 1.2rem;
        padding-bottom: 1rem;
        animation: open 1s ease-in-out;
      }
      @keyframes open {
        0% {
          transform: translateY(50%);
          opacity: 0;
        }
        100% {
          transform: translateY(0%);
        }
      }
      h2 {
        text-transform: capitalize;
        font-weight: 600;
        color: $secondery-color;
        padding-bottom: 0.8rem;
        font-size: 1rem;
        animation: opens 1s ease-in-out;
      }
      @keyframes opens {
        0% {
          transform: translateY(100%);
          opacity: 0;
        }
        100% {
          transform: translateY(0%);
        }
      }
    }
  }
}
