@mixin flexBetwn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@mixin all_heding {
  font-size: $heading-size;
  font-family: "Source Serif Pro", serif;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1.2rem;
}
@mixin all_p_hdig {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 2rem;
}

@mixin section_emt {
  h1 {
    @include all_heding;
  }
  p {
    @include all_p_hdig;
  }
}
.item {
  padding: 10px;
  .besic_price {
    background-color: $primary-color;
    padding: 2rem;
    height: 65vh;
    box-shadow: 7px 8px 12px -4px #00000033;
    border-radius: 10px;
    h2 {
      font-size: 2rem;
      letter-spacing: 1px;
      font-family: "Source Serif Pro", serif;
      font-weight: 700;
    }
    h3 {
      font-size: 2rem;
      font-weight: 800;
      padding-top: 1rem;
      font-family: "Source Serif Pro", serif;
    }

    h6 {
      font-size: 1.2rem;
      line-height: 1.8rem;
      opacity: 0.6;
      padding-bottom: 2rem;
      span {
        text-decoration: line-through;
      }
    }
    a {
      padding: 1rem;
      color: #000;
      font-size: 1rem;
      background: #bfdff9;
      font-weight: 800;
      box-shadow: 7px 8px 12px -4px #00000033;
      border-radius: 10px;
    }
    ul {
      padding-top: 1.5rem;
      li {
        display: block;
        color: $text-color;
        font-size: 1rem;
        line-height: 2rem;
      }
    }
  }
}
@mixin banner_info {
  width: 50%;
  h5 {
    color: #000;
    letter-spacing: 0.3rem;
    font-size: 1rem;
    font-weight: 400;
    padding-bottom: 0.8rem;
    font-family: "Josefin Sans", sans-serif;
  }
  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    padding-bottom: 2rem;
    font-family: "Source Serif Pro", serif;
  }

  p {
    margin: 2rem 0 0.5rem;
    font-size: 1rem;
    font-family: "Source Serif Pro", serif;
  }
  h2 {
    font-size: 2rem;
    padding-bottom: 1rem;
    font-family: "Source Serif Pro", serif;
    font-weight: 700;
  }
  ul {
    li {
      display: block;
    }
  }
}

@mixin routComp {
  .website_mobile {
    display: none;
  }
  .website {
    .wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 1rem;
      background-color: $white-color;
      box-shadow: $shdo;
      padding: 5px;
      margin-bottom: 2rem;
      .imgs {
        width: 50%;
      }
      img {
        width: 100%;
      }
      .info {
        text-align: start;
        width: 50%;
        h2 {
          a {
            font-size: 24px !important;
            padding-top: 1rem;
            color: $hding-color;
          }
        }
        p {
          border-bottom: 1px solid rgba(0, 0, 0, 0.397);
          padding-bottom: 0.5rem;
          color: $secondery-color;
          font-size: 0.8rem;
        }

        ul {
          padding-top: 1rem;
          li {
            list-style: square;
            display: block;
            font-size: 0.8rem;
            padding: 0.1rem;
            color: $text-color;
          }
        }
      }
      .btn_grp {
        padding-top: 1rem;
        width: 25%;
        h3 {
          font-size: 1rem;
          color: $secondery-color;
          letter-spacing: 0.1px;
          font-weight: 600;
          padding-bottom: 3rem;
        }
        button {
          display: block;
          background: linear-gradient(
            90deg,
            rgba(244, 124, 124, 1) 13%,
            rgba(191, 223, 249, 1) 94%
          );
          border: none;
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
          text-transform: uppercase;
          margin: 1rem 0;
          border-radius: 50px;
          font-weight: 600;
          width: 100%;
          color: $hding-color;
          box-shadow: $shdo;
        }
      }
    }
  }
}

@mixin routcompMobile {
  .website {
    display: none;
  }
  .back_button {
    margin-bottom: 1rem;
    a {
      display: inline;
      background: rgba(244, 124, 124, 1);

      color: $hding-color;
      padding: 0.5rem;
    }
  }
  .website_mobile {
    padding: 10rem 0 0;
    display: block;
    .wrap:nth-child(even) {
      animation: opensr 3s ease-in-out;
    }
    @keyframes opensr {
      0% {
        transform: translateY(50%);
        opacity: 0;
      }
      100% {
        transform: translateY(0%);
      }
    }
    .wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 1rem;
      background-color: $white-color;
      box-shadow: $shdo;
      padding: 5px;
      margin-bottom: 2rem;
      animation: opens 1s ease-in-out;
    
      .imgs {
        width: 40%;
      }
      img {
        width: 100%;
      }
      .info {
        text-align: start;
        width: 60%;
        h2 {
          a {
            font-size: 15px !important;
            padding-top: 1rem;
            color: $hding-color;
          }
        }
        p {
          border-bottom: 1px solid rgba(0, 0, 0, 0.397);
          padding-bottom: 0.5rem;
          color: $secondery-color;
          font-size: 0.6rem;
        }
        .btn_grp {
          padding-top: 1rem;

          h3 {
            font-size: 1rem;
            color: $secondery-color;
            letter-spacing: 0.1px;
            font-weight: 600;
            padding-bottom: 1rem;
          }
          button {
            display: inline;
            background: linear-gradient(
              90deg,
              rgba(244, 124, 124, 1) 13%,
              rgba(191, 223, 249, 1) 94%
            );
            border: none;
            padding: 0.3rem 1rem;
            font-size: 0.6rem;
            text-transform: uppercase;
            margin: 0rem 0.5rem 0 0;
            border-radius: 50px;
            font-weight: 600;
            color: $hding-color;
            box-shadow: $shdo;
          }
        }
      }
    }
    @keyframes opens {
      0% {
        transform: translateY(50%);
        opacity: 0;
      }
      100% {
        transform: translateY(0%);
      }
    }
  }
}
