$primary-color: #fad4d4;
$secondery-color: #f47c7c;
$third-color: #fff2f2;
$hding-color: rgba(0, 0, 0, 0.897);
$text-color: rgba(0, 0, 0, 0.74);
$white-color: #fff;

$shdo: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
$heading-size: 3rem;
$section-padding: 5rem 0;
$heading-padding: 3rem;
