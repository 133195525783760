@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "../variable/variable.scss";
@mixin paading {
    padding-bottom: 32px;
}
.whatsaap{
    padding: 78px 0;
    .wrap{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 2em;
        .head_info{
            h1{
                font-size: 42px;
                font-weight: 600;
                font-family: 'Poppins', sans-serif;
                line-height: 48px*1.2;
                font-style: normal;
                letter-spacing: 0.50px;
                @include paading();

            }
            h6{
                font-size: 14px;
                font-weight: 600;
                font-style: normal;
                color: #0000007b;
                @include paading();
                letter-spacing: 0.50px;
                font-family: 'Poppins', sans-serif;

            }
            .pera{
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;
                @include paading();
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                color: #000000b9;
                
            }
        }
        form{
            .dataInput{
                @include paading();
                label{
                    font-size: 16px;
                    line-height: 16px*1.5;
                    font-weight: 600;
                }
                p{
                    padding-bottom: 10px;
                    font-style: italic;
                    font-size: 14px;
                }
            }
            .box{
                .wats{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h3{
                        font-size: 18px;
                        font-weight: 600;
                        font-family: 'Poppins', sans-serif;
                        padding: 32px 0;
                    }
                }
                .adduser{
                    display: flex;
                    align-items: center;
                    justify-content: space-between; 
                    h6{
                        font-size: 16px;
                        color: #0000009c;
                    }
                }
                .addwaba{
                    display: flex;
                    align-items: center;
                    justify-content: space-between; 
                    h6{
                        font-size: 16px;
                        color: #0000009c;
                    }
                }
            }
            .subTotal{
                h2{
                    text-align: end;
                    padding: 32px 0;
                    font-size: 28px;
                    font-style: normal;
                    letter-spacing: 0.25px;
                    font-weight: 600;
                }

            }
            .button{
                display: flex;
                align-items: center;
                column-gap: 15px;
                input{
                    width: 10%;
                    height: 40px;
                    text-align: center;
                    border-radius: 3px;
                    border: 1px solid #0000007b;

                }
                button{
                    width: 90%;
                    height: 40px;
                    border: none;
                    background-color: $secondery-color;
                    color: #fff;
                    border-radius: 3px;
                    font-size: 18px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                }
            }
            
        }
    }
    .billForm{
        margin-top: 78px;
        h1{
            font-size: 28px;
            font-weight: 600;
            @include paading();
            font-family: 'Poppins', sans-serif;
            letter-spacing: 0.5px;

        }
        .names{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            align-items: center;
            column-gap: 15px;
        }
        .dataInput{
            @include paading();
            label{
                padding-bottom: 8px;
            }
        }
    }
}
@media screen and (max-width:1199px) {
    .whatsaap{
        padding: 78px 0;
        .wrap{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            column-gap: 2em;
            .image{
                img{
                    width: 100%;
                }
            }
            .head_info{
                h1{
                    font-size: 32px;
                    font-weight: 600;
                    font-family: 'Poppins', sans-serif;
                    line-height: 32px*1.2;
                    font-style: normal;
                    letter-spacing: 0.50px;
                    @include paading();
    
                }
                h6{
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    color: #0000007b;
                    @include paading();
                    letter-spacing: 0.50px;
                    font-family: 'Poppins', sans-serif;
    
                }
                .pera{
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    @include paading();
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    color: #000000b9;
                    
                }
            }
            form{
                .dataInput{
                    @include paading();
                    label{
                        font-size: 16px;
                        line-height: 16px*1.5;
                        font-weight: 600;
                    }
                    p{
                        padding-bottom: 10px;
                        font-style: italic;
                        font-size: 14px;
                    }
                }
                .box{
                    .wats{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h3{
                            font-size: 18px;
                            font-weight: 600;
                            font-family: 'Poppins', sans-serif;
                            padding: 32px 0;
                        }
                    }
                    .adduser{
                        display: flex;
                        align-items: center;
                        justify-content: space-between; 
                        h6{
                            font-size: 16px;
                            color: #0000009c;
                        }
                    }
                    .addwaba{
                        display: flex;
                        align-items: center;
                        justify-content: space-between; 
                        h6{
                            font-size: 16px;
                            color: #0000009c;
                        }
                    }
                }
                .subTotal{
                    h2{
                        text-align: end;
                        padding: 32px 0;
                        font-size: 24px;
                        font-style: normal;
                        letter-spacing: 0.25px;
                        font-weight: 600;
                    }
    
                }
                .button{
                    display: flex;
                    align-items: center;
                    column-gap: 15px;
                    input{
                        width: 10%;
                        height: 40px;
                        text-align: center;
                        border-radius: 3px;
                        border: 1px solid #0000007b;
    
                    }
                    button{
                        width: 90%;
                        height: 40px;
                        border: none;
                        background-color: $secondery-color;
                        color: #fff;
                        border-radius: 3px;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                    }
                }
                
            }
        }
        .billForm{
            margin-top: 78px;
            h1{
                font-size: 24px;
                font-weight: 600;
                @include paading();
                font-family: 'Poppins', sans-serif;
                letter-spacing: 0.5px;
    
            }
            .names{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                align-items: center;
                column-gap: 15px;
            }
            .dataInput{
                @include paading();
                label{
                    padding-bottom: 8px;
                }
            }
        }
    }
}
@media screen and (max-width:992px) and (min-width:768px){
    .whatsaap{
        padding: 78px 0;
        .wrap{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            column-gap: 2em;
            .image{
                padding-bottom: 20px;
                img{
                    width: 100%;
                }
            }
            .head_info{
                h1{
                    font-size: 42px;
                    font-weight: 600;
                    font-family: 'Poppins', sans-serif;
                    line-height: 48px*1.2;
                    font-style: normal;
                    letter-spacing: 0.50px;
                    @include paading();
    
                }
                h6{
                    font-size: 14px;
                    font-weight: 600;
                    font-style: normal;
                    color: #0000007b;
                    @include paading();
                    letter-spacing: 0.50px;
                    font-family: 'Poppins', sans-serif;
    
                }
                .pera{
                    font-size: 16px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    @include paading();
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    color: #000000b9;
                    
                }
            }
            form{
                .dataInput{
                    @include paading();
                    label{
                        font-size: 16px;
                        line-height: 16px*1.5;
                        font-weight: 600;
                    }
                    p{
                        padding-bottom: 10px;
                        font-style: italic;
                        font-size: 14px;
                    }
                }
                .box{
                    .wats{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h3{
                            font-size: 18px;
                            font-weight: 600;
                            font-family: 'Poppins', sans-serif;
                            padding: 32px 0;
                        }
                    }
                    .adduser{
                        display: flex;
                        align-items: center;
                        justify-content: space-between; 
                        h6{
                            font-size: 16px;
                            color: #0000009c;
                        }
                    }
                    .addwaba{
                        display: flex;
                        align-items: center;
                        justify-content: space-between; 
                        h6{
                            font-size: 16px;
                            color: #0000009c;
                        }
                    }
                }
                .subTotal{
                    h2{
                        text-align: end;
                        padding: 32px 0;
                        font-size: 28px;
                        font-style: normal;
                        letter-spacing: 0.25px;
                        font-weight: 600;
                    }
    
                }
                .button{
                    display: flex;
                    align-items: center;
                    column-gap: 15px;
                    input{
                        width: 10%;
                        height: 40px;
                        text-align: center;
                        border-radius: 3px;
                        border: 1px solid #0000007b;
    
                    }
                    button{
                        width: 90%;
                        height: 40px;
                        border: none;
                        background-color: $secondery-color;
                        color: #fff;
                        border-radius: 3px;
                        font-size: 18px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                    }
                }
                
            }
        }
        .billForm{
            margin-top: 78px;
            h1{
                font-size: 28px;
                font-weight: 600;
                @include paading();
                font-family: 'Poppins', sans-serif;
                letter-spacing: 0.5px;
    
            }
            .names{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                align-items: center;
                column-gap: 15px;
            }
            .dataInput{
                @include paading();
                label{
                    padding-bottom: 8px;
                }
            }
        }
    }
}

@media screen and (max-width:767px) and (min-width:320px){
    .whatsaap{
        padding: 150px 0 78px;
        .wrap{
            display: grid;
            grid-template-columns: repeat(1,1fr);
            column-gap: 2em;
            row-gap: 2em;
            .image{
                img{
                    width: 100%;
                }
            }
            .head_info{
                h1{
                    font-size: 24px;
                    font-weight: 600;
                    font-family: 'Poppins', sans-serif;
                    line-height: 24px*1.2;
                    font-style: normal;
                    letter-spacing: 0.50px;
                    @include paading();
    
                }
                h6{
                    font-size: 12px;
                    font-weight: 600;
                    font-style: normal;
                    color: #0000007b;
                    @include paading();
                    letter-spacing: 0.50px;
                    font-family: 'Poppins', sans-serif;
    
                }
                .pera{
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    @include paading();
                    font-family: 'Poppins', sans-serif;
                    font-style: normal;
                    color: #000000b9;
                    
                }
            }
            form{
                .dataInput{
                    @include paading();
                    label{
                        font-size: 14px;
                        line-height: 14px*1.5;
                        font-weight: 600;
                    }
                    p{
                        padding-bottom: 10px;
                        font-style: italic;
                        font-size: 12px;
                    }
                }
                .box{
                    .wats{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h3{
                            font-size: 12px;
                            font-weight: 600;
                            font-family: 'Poppins', sans-serif;
                            padding: 28px 0;
                        }
                    }
                    .adduser{
                        display: flex;
                        align-items: center;
                        justify-content: space-between; 
                        h6{
                            font-size: 12px;
                            color: #0000009c;
                        }
                    }
                    .addwaba{
                        display: flex;
                        align-items: center;
                        justify-content: space-between; 
                        h6{
                            font-size: 12px;
                            color: #0000009c;
                        }
                    }
                }
                .subTotal{
                    h2{
                        text-align: end;
                        padding: 28px 0;
                        font-size: 18px;
                        font-style: normal;
                        letter-spacing: 0.25px;
                        font-weight: 600;
                    }
    
                }
                .button{
                    display: flex;
                    align-items: center;
                    column-gap: 15px;
                    input{
                        width: 10%;
                        height: 40px;
                        text-align: center;
                        border-radius: 3px;
                        border: 1px solid #0000007b;
    
                    }
                    button{
                        width: 90%;
                        height: 40px;
                        border: none;
                        background-color: $secondery-color;
                        color: #fff;
                        border-radius: 3px;
                        font-size: 16px;
                        font-weight: 500;
                        letter-spacing: 0.5px;
                    }
                }
                
            }
        }
        .billForm{
            margin-top: 78px;
            h1{
                font-size: 24px;
                font-weight: 600;
                @include paading();
                font-family: 'Poppins', sans-serif;
                letter-spacing: 0.5px;
    
            }
            .names{
                display: grid;
                grid-template-columns: repeat(2,1fr);
                align-items: center;
                column-gap: 15px;
            }
            .dataInput{
                @include paading();
                label{
                    padding-bottom: 8px;
                    font-size: 14px;
                }
            }
        }
    }
}
