@import "../variable/variable.scss";
@import "../variable/mixin.scss";

.banner {
  background: linear-gradient(
    90deg,
    rgba(250, 212, 212, 1) 50%,
    rgba(191, 223, 249, 1) 50%
  );
  .item_wrap {
    @include flexBetwn;
    align-items: stretch;
    padding: 3rem 0;
    column-gap: 10rem;
    .left_info {
      @include banner_info;
      a {
        padding: 1rem;
        color: #000;
        font-size: 1rem;
        background: #bfdff9;
        font-weight: 800;
        box-shadow: 7px 8px 12px -4px #00000033;
        border-radius: 10px;
      }
    }
    .right_info {
      @include banner_info;
      a {
        background-color: #f8d3d3;
        padding: 1rem;
        color: #000;
        font-size: 1rem;
        font-weight: 800;
        box-shadow: 7px 8px 12px -4px #00000033;
        border-radius: 10px;
      }
    }
  }
}
.about{
  text-align: center;
  padding: $section-padding;
  h1{
    font-size: $heading-size;
    padding-bottom: 2rem;
  }
  p{
    font-size: 1.2rem;
  }
}
.web_ebmt {
  padding: $section-padding;
  .web_hding {
    h1 {
      @include all_heding;
    }
    p {
      @include all_p_hdig;
    }
  }
  .item {
    padding: 10px;
    .besic_price {
      background-color: $primary-color;
      padding: 3rem;
      height: 65vh;
      box-shadow: 7px 8px 12px -4px #00000033;
      border-radius: 10px;
      h2 {
        font-size: 2rem;
        letter-spacing: 1px;
        font-family: "Source Serif Pro", serif;
        font-weight: 700;
      }
      h3 {
        font-size: 2rem;
        font-weight: 800;
        padding-top: 1rem;
        font-family: "Source Serif Pro", serif;
      }

      h6 {
        font-size: 1.2rem;
        line-height: 1.8rem;
        opacity: 0.6;
        padding-bottom: 2rem;
        span {
          text-decoration: line-through;
        }
      }
      a {
        padding: 1rem;
        color: #000;
        font-size: 1rem;
        background: #bfdff9;
        font-weight: 800;
        box-shadow: 7px 8px 12px -4px #00000033;
        border-radius: 10px;
      }
      ul {
        padding-top: 1.5rem;
        li {
          display: block;
          color: $text-color;
          font-size: 1rem;
          line-height: 2rem;
        }
      }
    }
  }
}
.cloud_ebmt {
  background-color: $third-color;
  padding: $section-padding;
  @include section_emt;
}

.demo_projects {
  //   background-color: $secondery-color;
  padding: $section-padding;
  text-align: center;
  h1 {
    @include all_heding;
  }
  .items {
    padding: 20px;
    .projects {
      padding: 0.5rem;
      background-color: $primary-color;
      border-radius: 10px;
      box-shadow: 7px 8px 12px -4px #00000033;
      position: relative;

      img {
        width: 100%;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }
      h3 {
        background-color: #fff;
        text-align: center;
        padding: 1rem 0;
        font-family: "Source Serif Pro", serif;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        color: $secondery-color;
      }
    }
  }
}

@media screen and (max-width: 448px) {
  .banner {
    background: linear-gradient(
      180deg,
      rgba(250, 212, 212, 1) 55%,
      rgba(191, 223, 249, 1) 45%
    );
    .item_wrap {
      @include flexBetwn;
      flex-direction: column;
      align-items: stretch;
      .left_info {
        width: 100%;
        padding: 6rem 0 0;
        h5 {
          font-size: 0.7rem;
        }
        h1 {
          font-size: 1.5rem;
        }

        p {
          margin: 1.5rem 0 0.5rem;
          font-size: 0.7rem;
        }
        h2 {
          font-size: 1.5rem;
        }
        ul {
          li {
            display: block;
            font-size: 0.7rem;
          }
        }
        a {
          padding: 0.7rem;
          color: #000;
          font-size: 0.7rem;
          background: #bfdff9;
          font-weight: 800;
          box-shadow: 7px 8px 12px -4px #00000033;
          border-radius: 10px;
        }
      }
      .right_info {
        width: 100%;
        padding: 3rem 0 0;
        h5 {
          font-size: 0.7rem;
        }
        h1 {
          font-size: 1.5rem;
        }

        p {
          margin: 1.5rem 0 0.5rem;
          font-size: 0.7rem;
        }
        h2 {
          font-size: 1.5rem;
        }
        ul {
          li {
            display: block;
            font-size: 0.7rem;
          }
        }
        a {
          padding: 0.7rem;
          color: #000;
          font-size: 0.7rem;
          background: $secondery-color;
          font-weight: 800;
          box-shadow: 7px 8px 12px -4px #00000033;
          border-radius: 10px;
        }
      }
    }
  }

  .web_ebmt {
    padding: $section-padding;
    .web_hding {
      h1 {
        font-size: 1.1rem;
      }
      p {
        font-size: 0.8rem;
      }
    }
    .item {
      padding: 10px;
      text-align: center;
      .besic_price {
        background-color: $primary-color;
        padding: 1rem;
        height: 50vh;
        box-shadow: 7px 8px 12px -4px #00000033;
        border-radius: 10px;
        h2 {
          font-size: 1.1rem;
          letter-spacing: 1px;
          font-family: "Source Serif Pro", serif;
          font-weight: 700;
        }
        h3 {
          font-size: 1.5rem;

          padding-top: 0.5rem;
        }

        h6 {
          font-size: 0.8rem;
          line-height: 1.8rem;

          padding-bottom: 1rem;
          span {
            text-decoration: line-through;
          }
        }
        a {
          padding: 0.8rem;
          font-size: 0.8rem;
        }
        ul {
          padding-top: 1.5rem;
          li {
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
  .cloud_ebmt {
    background-color: $third-color;
    padding: 3rem 0;
    .web_hding {
      h1 {
        font-size: 1rem;
      }
      p {
        font-size: 0.7rem;
      }
    }
    .item {
      padding: 10px;
      text-align: center;
      .besic_price {
        background-color: $primary-color;
        padding: 1rem;
        height: 50vh;
        box-shadow: 7px 8px 12px -4px #00000033;
        border-radius: 10px;
        h2 {
          font-size: 1.1rem;
          letter-spacing: 1px;
          font-family: "Source Serif Pro", serif;
          font-weight: 700;
        }
        h3 {
          font-size: 1.5rem;

          padding-top: 0.5rem;
        }

        h6 {
          font-size: 0.8rem;
          line-height: 1.8rem;

          padding-bottom: 1rem;
          span {
            text-decoration: line-through;
          }
        }
        a {
          padding: 0.8rem;
          font-size: 0.8rem;
        }
        ul {
          padding-top: 1.5rem;
          li {
            font-size: 0.8rem;
            line-height: 1.5rem;
          }
        }
      }
    }
  }

  .demo_projects {
    //   background-color: $secondery-color;
    padding: 3rem 0;
    text-align: center;
    h1 {
      font-size: 1rem;
    }
    .items {
      padding: 10px;
      width: 70% !important;
      .projects {
        padding: 0.5rem;
        background-color: $primary-color;
        border-radius: 10px;
        box-shadow: 7px 8px 12px -4px #00000033;
        position: relative;

        img {
          width: 100%;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }
        h3 {
          background-color: #fff;
          text-align: center;
          padding: 1rem 0;
          font-family: "Source Serif Pro", serif;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          color: $secondery-color;
        }
      }
    }
  }
}
