@import "/src/variable/variable.scss";
* {
  margin: 0;
  font-family: "Work Sans", sans - serif;
}
body {
  margin: 0;
  // background-color: #fff2f2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  display: inline;
}
a {
  text-decoration: none;
}
.slick-prev,
.slick-next {
  width: 50px;
  height: 50px;
  background-color: $secondery-color;
  box-shadow: "7px 8px 12px -4px #00000033";
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #000;
  outline: none;
  background-color: $secondery-color;
}

.slick-prev::before,
.slick-next::before {
  color: #000;
}
.slick-next {
  right: -55px;
}

.slick-prev {
  left: -55px;
}

.slick-dots {
  bottom: -2.5vw;
  li {
    button {
      width: 20px;
      height: 20px;
      border: 1px solid $secondery-color;
      border-radius: 20px;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        content: "";
        text-align: center;
        opacity: 0.5;
        color: #000;
        background-color: $secondery-color;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
      }
      &.slick-active,
      &:hover {
        button {
          border-color: $secondery-color;
          &::before {
            background-color: $secondery-color;
            opacity: 3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 448px) {
  .slick-dots {
    display: none;
  }
}
