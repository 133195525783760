@import "../variable/variable.scss";
@import "../variable/mixin.scss";

.service {
  background-color: #f1f6ff;
  padding-bottom: 5rem;
  .heading {
    background: radial-gradient(
        circle,
        rgb(244 124 124 / 76%) 17%,
        rgb(191 223 249 / 94%) 94%
      ),
      url("https://www.cyberhelpindia.com/images/inner/about.jpg");
    text-align: center;
    margin-bottom: 5rem;
    padding: 5rem;
    h1 {
      font-size: $heading-size;
      font-weight: 600;
      word-spacing: 5px;
    }
    p {
      padding-top: 1rem;
      font-size: 1.2rem;
      font-weight: 500;
      a {
        color: $white-color;
        padding-left: 1rem;
      }
    }
  }
  .flx_box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 1rem;

    .left_wrap {
      width: 30%;
      ul {
        .ul_hed {
          background-color: $secondery-color;
          padding: 0.7rem;
          color: $white-color;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 0.5px;
          word-spacing: 0.2em;
          display: block;
        }
        li {
          display: flex;
          align-items: center;
          padding: 1rem;
          margin-bottom: 1.5rem;
          box-shadow: $shdo;
          border-radius: 10px;
          img {
            width: 10%;
            margin-right: 1rem;
          }
          a {
            text-transform: uppercase;
            font-size: 1rem;
            color: $hding-color;
            font-weight: 500;
            word-spacing: 0.2em;
            letter-spacing: 0.5px;
          }
        }
      }
    }
    .left_wrap_mob {
      display: none;
    }
    .wrap {
      // height: 75vh;
      // overflow: hidden;
      // overflow-y: scroll;
      // padding-bottom: 2rem;
      // scrollbar-width: thin;
      // scroll-behavior: smooth;
      .rit_wrp {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 448px) {
  .service {
    background-color: #f1f6ff;
    padding-bottom: 5rem;
    .heading {
      background: radial-gradient(
          circle,
          rgb(244 124 124 / 76%) 17%,
          rgb(191 223 249 / 94%) 94%
        ),
        url("https://www.cyberhelpindia.com/images/inner/about.jpg");
      text-align: center;
      margin-bottom: 5rem;
      padding: 10rem 1rem 3rem;
      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        word-spacing: 5px;
      }
      p {
        padding-top: 1rem;
        font-size: 0.8rem;
        font-weight: 500;
        a {
          color: $white-color;
          padding-left: 1rem;
        }
      }
    }
    .flx_box {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      column-gap: 1rem;
      flex-direction: column;
      .left_wrap {
        display: none;
      }
      .left_wrap_mob {
        display: block;
        width: 100%;
        ul {
          .ul_hed {
            background-color: $secondery-color;
            padding: 0.7rem;
            color: $white-color;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 0.5px;
            word-spacing: 0.2em;
            display: block;
          }
          display: inline;
          li {
            display: flex;
            align-items: center;
            padding: 1rem;
            margin-bottom: 1.5rem;
            box-shadow: $shdo;
            border-radius: 10px;
            img {
              width: 10%;
              margin-right: 1rem;
            }
            a {
              text-transform: uppercase;
              font-size: 1rem;
              color: $hding-color;
              font-weight: 500;
              word-spacing: 0.2em;
              letter-spacing: 0.5px;
            }
          }
        }
      }

      .wrap {
        display: none;
        // height: 75vh;
        // overflow: hidden;
        // overflow-y: scroll;
        // padding-bottom: 2rem;
        // scrollbar-width: thin;
        // scroll-behavior: smooth;
        .rit_wrp {
          width: 100%;
          display: none;
        }
      }
    }
  }
}
