@import "../variable/variable.scss";
@import "../variable/mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap");

.navbar.colorChange {
  position: fixed;
  width: 100%;
  background-color: #fff;
  animation: animed 0.6s ease-in-out;
  top: 0;
  z-index: 4;
  box-shadow: $shdo;
}
@keyframes animed {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.navbar {
  .navbar_hlt {
    background-color: $secondery-color;
    padding: 0.5rem 0;
    .flx_nav {
      @include flexBetwn;
      h6 {
        a {
          color: $text-color;
          font-weight: 600;
          letter-spacing: 0.5px;
        }
      }
    }
  }

  .navbar_main_menu {
    .main_menu {
      @include flexBetwn;
      .logo {
        h1 {
          a {
            font-size: $heading-size;
            font-family: "Libre Barcode 39 Text", cursive;
            color: $hding-color;
            font-weight: 500;
          }
        }
      }
      .menu {
        ul {
          li {
            padding: 0 0.7rem;
            a {
              font-size: 1rem;
              font-weight: 500;
              color: rgb(51, 51, 51);
              position: relative;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              &:hover {
                color: $secondery-color;
              }
              &:hover::after {
                background-color: $secondery-color;
                width: auto;
                height: 3px;
                position: absolute;
                top: 1.2em;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                transition: 0.6s ease-in-out;
                animation: slider 0.6s ease-in-out;
              }
              @keyframes slider {
                0% {
                  right: 100%;
                }
                100% {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
.mobile_nav {
  display: none;
}
.toggle_menu {
  display: none;
}
@media only screen and (max-width: 767px) {
  .navbar_main_menu {
    display: none;
  }

  .mobile_nav.colorChange {
    position: fixed;
    width: 100%;
    background-color: #fff;
    animation: animed 0.6s ease-in-out;
    top: 0;
    z-index: 6;
    box-shadow: $shdo;
  }
  @keyframes animed {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  .navbar {
    display: none;
  }
  .mobile_nav {
    display: block;
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 6;
    .navbar_hlt {
      background-color: $secondery-color;
      padding: 0.5rem 0;
      .flx_nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h6 {
          a {
            color: $text-color;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 1.5rem;
            font-size: 0.8rem;
          }
        }
      }
    }
    .nav_bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .logo {
        a {
          font-size: 2rem;
          font-family: "Libre Barcode 39 Text", cursive;
          color: $hding-color;
          font-weight: 500;
        }
      }
      .toggle_button {
        button {
          position: relative;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          border: none;
          background-color: transparent;
          display: block;
          z-index: 4;

          &.menuicons span {
            transform: translateX(60px);
          }

          &.menuicons span:nth-child(1) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(50deg);
          }

          &.menuicons span:nth-child(2) {
            transform: translateY(0px);
            width: 20px;
            transform: rotate(128deg);
          }

          & span {
            position: absolute;
            width: 30px;
            height: 2.5px;
            background-color: $secondery-color;
            border-radius: 4px;
            transition: 0.5s;
            right: 15px;

            &:nth-child(1) {
              transform: translateY(-10px);
              width: 20px;
              left: 15px;
            }

            &:nth-child(2) {
              transform: translateY(10px);
              width: 20px;
              left: 15px;
            }
          }
        }
        .menu-one {
          display: none;
        }
      }
    }
  }
  .toggle_menu {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    content: "";
    z-index: 5;
    box-shadow: 1px 10px 11px 3px #00000017;
    font-family: "Kanit", sans-serif;
    background-color: $secondery-color;
    margin: 0;
    transform: translate(110%);
    padding-top: 6rem;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;
    .menus {
      // background-color: #;
      padding: 3rem 0;
      ul {
        padding: 0;
        .dropdown {
          padding: 0;
        }
        li {
          display: block;

          text-align: center;
          margin: 0rem 2rem;
          padding: 0.5rem;
          border-bottom: 1px solid rgb(199, 198, 198);
          .dropdown-toggle {
            position: relative;
            &::after {
              display: inline-block;
              margin-left: 0;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.3em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: absolute;
              top: 50%;
              right: 0;
            }
          }
          a {
            font-size: 1rem;
            font-weight: 900;
            letter-spacing: 0rem;
            font-family: "Kanit", sans-serif;
            line-height: 2rem;
            text-decoration: none;
            color: $primary-color;

            &.active {
              color: #fff;
            }
          }
          ul {
            background-color: transparent;
            border: none;
            li {
              padding: 0;
              background-color: #fff;
              margin: 0rem;
              padding: 0 1rem;
            }
          }
        }
      }
    }
    .bottom {
      padding-top: 3rem;
    }
    .add {
      position: relative;
      top: 0;
      bottom: 0;
      background-color: $primary-color;
      height: auto;
      color: #fff;
      padding: 2rem 2rem 0;
      text-align: center;
      i {
        font-size: 0.9rem;

        padding-bottom: 1rem;
      }
      h1 {
        font-size: 1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
        font-family: "Kanit", sans-serif;
      }
      h6 {
        padding-bottom: 2rem;
        border-bottom: 1px solid #f3f0f040;
        font-size: 0.8rem;
        opacity: 0.5;
        font-family: "Kanit", sans-serif;
      }
      p {
        font-size: 0.8rem;
        opacity: 0.5;
        padding-bottom: 1rem;
      }
      ul {
        li {
          border: none;
          display: inline;
          padding: 0;
          margin: 0 0.5rem;
          background-color: transparent;
          a {
            color: #fff;
            font-size: 1.2rem;
            opacity: 0.5;
          }
        }
      }
    }
  }
  .actives {
    transform: translate(0%);
    transition: 0.8s;
  }
}
